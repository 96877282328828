import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import Link from '/src/components/LocalizedLink';
import ForthcomingCourses from '../../components/ForthcomingCourses';
import AplicationProcess from '../../components/AplicationProcess';
import ReviewsSection from '../../components/ReviewsSection';
import Faq from '../../components/Faq';

import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../../assets/images/svg/arrow-right-red.inline.svg';
import iconCalendar from '../../assets/images/svg/calendar.svg';
import iconClock from '../../assets/images/svg/clock.svg';
import iconTick from '../../assets/images/svg/tick.svg';
// import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';

import imgBenefit1 from '../../assets/images/online-trinity-course/Internationally Recognized Qualification.svg';
import imgBenefit2 from '../../assets/images/online-trinity-course/100% Online.svg';
import imgBenefit3 from '../../assets/images/online-trinity-course/Small Group Sizes .svg';
import imgBenefit5 from '../../assets/images/online-trinity-course/Post-Grad Job Prospects 2.svg';
import imgReview from '../../assets/images/trinity-cert-tesol-course-page/Trinity CertTESOL - Jimena Lopez.png';

import iconQuotes from '../../assets/images/trinity-cert-tesol-course-page/quotes.svg';
import imgDots from '../../assets/images/trinity-cert-tesol-course-page/Dots.svg';
import imgPhoto1 from '../../assets/images/online-trinity-course/photo 1 (8).png';

import iconAccomodation from '../../assets/images/student-support/Accommodation (1).svg';
import iconStudentVisa from '../../assets/images/student-support/Student visas (1).svg';
import iconCulture from '../../assets/images/student-support/Culture.svg';
import iconBureaucracy from '../../assets/images/student-support/Bureaucracy.svg';

import fileTrinityCertTESOLSyllabus from '../../assets/files/CertTESOL-syllabus-Jan-2016.pdf';
import fileTEFLIberiaBrochure from '../../assets/files/TEFL Iberia brochure web.pdf';

import '../../assets/styles/pages/online-trinity-course-page.scss';

import fileAssesmentCriteria from '../../assets/files/Trinity CertTESOL assessment guidelines.pdf';
const assesmentCriteria = `Download full assesment criteria <a href='${fileAssesmentCriteria}' target="_blank" class="red-link">(pdf)</a>`;

const week1 = `
  <h5>Unit 1 incorporates the following: </h5>
    <p class='c-text-14'>&#10004; Teaching Practice (60%)</p>
    <p class='c-text-14'>&#10004; Teaching Practice Portfolio (25%) </p>
    <p class='c-text-14'>&#10004; Guided Observation Journal (GOJ) (15%)</p>
    <h5>1. Teaching Practice</h5>
      <ul class='faq-list'>
        <li class='faq-list__item faq-list__item--no-marker'>You will be assessed on:</li>
        <li class='faq-list__item'>Lesson preparation and quality of lesson plan </li>
        <li class='faq-list__item'>Material adaptation / creation and use </li>
        <li class='faq-list__item'>Appropriateness of lesson aims </li>
        <li class='faq-list__item'>Achievement of teaching objectives </li>
        <li class='faq-list__item'> Teaching techniques and methods applied in lesson </li>
        <li class='faq-list__item'>Dealing with / analysing language  </li>
        <li class='faq-list__item'>Class rapport  </li>
        <li class='faq-list__item'>Reflecting on teaching </li>
      </ul>
      <h5>2. Teaching Practice Portfolio </h5>
        <ul class='faq-list'>
            <li class='faq-list__item faq-list__item--no-marker'>The Teaching Practice Portfolio will assess:</li>
            <li class='faq-list__item'>Lessons including the lesson plan</li>
            <li class='faq-list__item'>Self evaluations</li>
            <li class='faq-list__item'>Final reflective summary </li>
        </ul>
      <h5>3. Guided Observation Journal (GOJ)</h5>
        <ul class='faq-list'>
            <li class='faq-list__item'>During the course you will undertake 4 hours of video classroom observation. For each lesson you observe, you will be given an observation task focusing on different aspects of the lesson each time.</li>
        </ul>`;

const week2 = `
                <h5>Unit 2 incorporates the following: </h5>
                   <p class='c-text-14'>&#10004; 5 homework tasks </p>
                   <p class='c-text-14'>&#10004; Trinity CertTESOL Language Awareness Exam
                   </p>
                <h5>1. Homework Tasks</h5>
                <ul class='faq-list'>
                    <li class='faq-list__item faq-list__item--no-marker'>During the course you will receive a total of 5 homework tasks:</li>
                    <li class='faq-list__item'>Lexis homework  </li>
                    <li class='faq-list__item'>Pronunciation homework </li>
                    <li class='faq-list__item'>Grammar 1 homework</li>
                    <li class='faq-list__item'>Grammar 2 homework </li>
                    <li class='faq-list__item'>Grammar 3 homework</li>
                </ul>
                <h5>2. Language Awareness Exam</h5>
                <ul class='faq-list'>
                    <li class='faq-list__item'>At the end of the 2 week you will receive an open-book, self-paced language awareness  exam to be completed by a set time given by the course tutor. The pass mark for the exam is 60%.</li>
                </ul>
                `;

const week3 = `
                <h5>Unit 3 incorporates the following: </h5>
                <p class='c-text-14'>&#10004; Need analysis of an assigned English language learner</p>
                <p class='c-text-14'>&#10004; Learner competency analysis</p>
                <p class='c-text-14'>&#10004; One-to-one lesson</p>

                <h5>1. Needs analysis</h5>
                <ul class='faq-list'>
                    <li class='faq-list__item faq-list__item--no-marker'>In this section you will be required to analyse the following details of the learner:</li>
                    <li class='faq-list__item'>Social and cultural background</li>
                    <li class='faq-list__item'>Experience of English</li>
                    <li class='faq-list__item'>Use of English now and in the future</li>
                    <li class='faq-list__item'>Reasons for learning English</li>
                </ul>
                <h5>2. Learner competency analysis</h5>
                <ul class='faq-list'>
                    <li class='faq-list__item'>Reading competence</li>
                    <li class='faq-list__item'>Listening competence</li>
                    <li class='faq-list__item'>Communicative competence  </li>
                    <li class='faq-list__item'>Speaking - orthographic transcription of fragment of speech </li>
                    <li class='faq-list__item'>Speaking - phonemic transcription of fragment of speech  </li>
                    <li class='faq-list__item'>Speaking - strengths of pronunciation </li>
                    <li class='faq-list__item'>Speaking - errors of phonology </li>
                    <li class='faq-list__item'>Writing - linguistic strengths </li>
                    <li class='faq-list__item'>Writing - linguistic weaknesses </li>
                    <li class='faq-list__item'>Analysis of writing competence (register, cohesion, coherence) </li>
                </ul>
                <h5>3. One-to-one lesson </h5>
                <ul class='faq-list'>
                    <li class='faq-list__item'>Rationale for follow-up lesson objectives</li>
                    <li class='faq-list__item'>Lesson aims & anticipated problems </li>
                    <li class='faq-list__item'>Language analysis </li>
                    <li class='faq-list__item'>Lesson procedure</li>
                    <li class='faq-list__item'>Post-lesson evaluation & reflection assessment guidelines </li>
                    <li class='faq-list__item'>Further study - five further lessons with rationale and link to identified needs</li>
                    <li class='faq-list__item'>Summary and comparison of private and group classes</li>
                </ul>
                `;

const week4 = `
  <h5>Unit 2 incorporates the following: </h5>
  <p class='c-text-14'>&#10004; Written communication skills analysing a chosen, self-made teaching material </p>
  <p class='c-text-14'>&#10004; Spoken communication skills analysing a chosen, self-made teaching material</p>
      <h5>1. Written analysis</h5>
      <ul class='faq-list'>
          <li class='faq-list__item'>Identify a linguistic purpose and specific objectives.</li>
          <li class='faq-list__item'>Explain how the materials were appropriate to the students’ linguistic needs.</li>
          <li class='faq-list__item'>Identify potential difficulties of the materials for the learners and solutions for dealing with them.</li>
          <li class='faq-list__item'>Discuss effectively how the materials were used in the classroom.</li>
          <li class='faq-list__item'>Evaluate the success of the materials.</li>
          <li class='faq-list__item'>Suggest how the materials might be improved.</li>
      </ul>
      <h5>2. Spoken analysis</h5>
      <ul class='faq-list'>
          <li class='faq-list__item'>Discuss the suitability of the materials both in terms of needs and motivations for the learners.</li>
          <li class='faq-list__item'>Discuss what problems arose from the materials and how these were dealt with.</li>
          <li class='faq-list__item'>Discuss how the learners responded to the materials and why the trainee thought this was the case.</li>
          <li class='faq-list__item'>Discuss what the learning outcomes were from the materials and how the materials might be improved.</li>
          <li class='faq-list__item'>Demonstrate what they have learnt about the selection and production of English language teaching materials.</li>
          <li class='faq-list__item'>Demonstrate what they have learnt about teaching English using materials from different sources.</li>
      </ul> `;

const week5 = `
                <h5>Unit 5 incorporates the following: </h5>
                <p class='c-text-14'>&#10004; Lesson observation and analysis </p>
                <p class='c-text-14'>&#10004; Journal Summary</p>
                <h5>1. Lesson observation and analysis </h5>
                 <ul class='faq-list'>
                  <li class='faq-list__item'>Analyse the linguistic aims on the lessons</li>
                  <li class='faq-list__item'>Describe materials used and their purposes </li>
                  <li class='faq-list__item'>Analyse classroom management techniques</li>
                  <li class='faq-list__item'>Analyse correction methods </li>
                  <li class='faq-list__item'>Analyse interaction patterns</li>
                 </ul>
                 <h5>2. Journal summary </h5>
                   <ul class='faq-list'>
                      <li class='faq-list__item'>Develop a detailed summary about what you and at least one other peer have learnt from the experience about language teaching and learning. </li>
                  </ul> `;

const OnlineTrinityCourse = ({ data }) => {
  const allPrismicCourses = data.allPrismicCourses.edges;
  const [isMore, setIsMore] = useState(false);

  return (
    <Layout
      title={{ id: 'onlineCerttesol.seo.title' }}
      description={{ id: 'onlineCerttesol.seo.description' }}
    >
      <div className="online-trinity-course-page">
        <section className="c-first-section c-first-section--sm">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9 col-xl-7">
                <div className="c-first-section__schedule">
                  <div className="item__wrapper">
                    <img src={iconCalendar} alt="iconCalendar" />
                    <span className="c-text-14">12 weeks</span>
                  </div>
                  <div className="item__wrapper">
                    <img src={iconClock} alt="iconClock" />
                    <span className="c-text-14">160-hour course</span>
                  </div>
                </div>
                <h1 className="c-first-section__title">
                  Part-Time Online Trinity CertTESOL Course
                </h1>
                <p className="c-first-section__description">
                  Complete the prestigious, internationally recognised course online in 12 weeks
                </p>
                <div className="c-first-section__btns">
                  <Link to="/apply/" className="c-btn c-btn--red">
                    Apply Now
                    <IconArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="c-title-34 second-section__l">
                  Combine Study With Your Current Job
                </h2>
                <p className="c-text-18 description second-section__l">
                  The 100% online Trinity CertTESOL course allows you to train and qualify in 12
                  weeks, without the need to quit your job or move abroad. With this internationally
                  recognised qualification you’ll be prepared and qualified to find work both online
                  and in person at language academies.
                </p>
                {/* <p className="notice c-text-18">
                  Eligible for government grants under Covid retraining schemes.
                </p> */}
                <div className="c-benefit-list second-section__l">
                  <h4 className="c-text-16">This course is ideal for you if:</h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You want to combine study with a full-time job</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      You want to get an internationally recognised teaching qualification online.
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You enjoy online study.</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      You want to retrain and earn reliable income from a teaching job.
                    </p>
                  </div>
                </div>
                <Link to="/guide-to-teaching-english-online/" className="c-btn c-btn--red mt-20">
                  Get your free online teaching guide
                  <IconArrowRight />
                </Link>
              </div>
              <div className="col-md-6">
                <div className="wrapper">
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit1} alt="imgBenefit1" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Internationally Recognized Qualification</h3>
                      <p className="c-text-14">
                        Barcelona? Bangkok? Brazil? Trinity CertTESOL is accepted across the globe.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit2} alt="imgBenefit2" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">100% Online</h3>
                      <p className="c-text-14">
                        You’ll join live online sessions every week with the tutor and other
                        students.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit3} alt="imgBenefit3" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Super Small Group Sizes</h3>
                      <p className="c-text-14">
                        Our classes max out at 8, giving everybody access to an instructor.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit5} alt="imgBenefit5" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Post-Grad Job Prospects</h3>
                      <p className="c-text-14">
                        We work hard to ensure that all our students find relevant work after
                        graduation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-includes c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgReview} alt="" className="img-main" />
                    <img src={imgDots} alt="" className="dots" />
                  </div>
                  <div className="block_l__text">
                    <img src={iconQuotes} alt="" />
                    <p>
                      I had THE best time at TEFL Iberia. I didn't really know what to expect before
                      starting the course and it turned out to be nothing but excellent. The course
                      was very intense, but it was so well-structured and the whole staff was so
                      professional and ready to go the extra mile, that in just a month I found
                      myself perfectly able to teach English.
                      <br />
                      <strong>Jimena Lopez</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block_r">
                  <h2 className="c-title-34">What’s included?</h2>
                  <div className="c-benefit-list block_r__benefits">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        160-hour Trinity CertTESOL course and qualification.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Expert tuition from professional teachers via video conferencing and
                        discussion forums.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Trinity CertTESOL examination and certificate fees.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Course workbook sent to your home address.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        6+ hours of observed teaching practice with real learners.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Job contacts and placement help – our host school hires exclusively from
                        TEFL Iberia graduates.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Dedicated course coordinator.</p>
                    </div>
                    {/* <h4>BONUS</h4>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        2-day classroom teaching workshop in Barcelona (post pandemic)
                      </p>
                    </div> */}
                  </div>
                  <div className="block_r__price block_r__price--full">
                    <h3 className="c-text-13">Full Price:</h3>
                    <h3 className="c-title-32">1550€</h3>
                  </div>
                  <div className="block_r__price block_r__price--early-bird">
                    <h3 className="c-text-13">
                      Early Bird Price:{' '}
                      <span className="notion">Pay in full 4 weeks in advance</span>
                    </h3>
                    <h3 className="c-title-32">1400€</h3>
                  </div>
                  <div className="block_r__btns">
                    <Link to="/apply" className="c-btn c-btn--red">
                      Apply Now
                      <IconArrowRight />
                    </Link>
                    <a
                      href={fileTEFLIberiaBrochure}
                      className="c-btn c-btn--red-border"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Brochure
                      <IconArrowRightRed />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="internal-section c-section-py">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-lg-6 col-md-7 order-md-first order-last">
                <div className="block_l">
                  <h2 className="c-title-34 block__title">
                    What will the 100% Online Trinity CertTESOL{' '}
                    <span className="colored no-link">be like?</span>
                  </h2>
                  <p className="c-text-18 block__subtitle">
                    Our 100% Online Trinity CertTESOL course is done via Zoom and does not require
                    any in-person training at all. Your workshops, lesson preparation, teaching
                    practice and assignments will all be carried out online.
                  </p>
                  <p className="c-text-16 block__description">
                    The Trinity CertTESOL course will take part over 12 weeks, with 10 hours per
                    week of live tutor time, plus 5 hours per week of self study and assignments.
                    See below for more detailed information on the timetable.
                  </p>
                  <p className="c-text-16 block__description">
                    The timetable has been designed to cater for people who have full time work or
                    study, and would like to combine their teacher training with other commitments.
                  </p>
                  {/* <p className="c-text-16 block__description">
                    The course timetable has been carefully chosen to satisfy students in as many
                    time zones as possible and we may open up additional sessions at different times
                    to cater to even more students.
                  </p>
                  <p className="c-text-16 block__description">
                    The course is Monday - Friday and you will take part in live training sessions
                    every day, with the opportunity to spend time in smaller groups as you would in
                    a real classroom. You’ll also get plenty of time for self-study and lesson
                    preparation, with a live tutor and group support at the click of a button.
                    You’ll teach lessons online to real groups of students and receive detailed
                    feedback from your course tutor.
                  </p> */}
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-5 order-md-last order-first">
                <div className="block_r">
                  <div className="block_r__img">
                    <img src={imgPhoto1} alt="Photo1" className="block_r__img" />
                  </div>
                  <div className=" block_r__trinity">
                    <img src={imgDots} alt="iconTrinity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="eigth-section c-faqs-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <h3 className="c-title-34">
                  <FormattedMessage id="onlineCerttesol.sec8.title" />
                </h3>
                <p className="c-text-18">
                  {/* The 100% Online Trinity CertTESOL course is 160 hours long and takes place over 12
                  weeks.{' '}
                  <span className="text-with-margin">
                    The live tutor sessions take place on Tuesday, Wednesday and Thursdays.
                  </span>
                  <span className="text-with-margin">
                    There are two timetables available to cater for different time zones:
                  </span> */}
                  <FormattedMessage
                    id="onlineCerttesol.sec8.description"
                    values={{
                      accent: (chunk) => <span className="text-with-margin">{chunk}</span>,
                      accent2: (chunk) => <span className="text-with-margin">{chunk}</span>,
                    }}
                  />
                  <ul className="timetable-list">
                    <li className="timetable-list__item">
                      {/* 6pm - 9pm (EST - suitable for North Americans) */}
                      <FormattedMessage id="onlineCerttesol.sec8.notification.time1" />
                    </li>
                    <li className="timetable-list__item">
                      {/* 6pm - 9pm (GMT - suitable for Europeans) */}
                      <FormattedMessage id="onlineCerttesol.sec8.notification.time2" />
                    </li>
                  </ul>
                </p>

                {/* <div className="assesment-block">
                  <h4 className="c-title-20">Is English not your native language?</h4>
                  <p className="c-text-16">
                    You need to demonstrate a C1 level in order to be able to do the course.
                  </p>
                  <a
                    href="https://www.tryinteract.com/share/quiz/5fa530f1849de80014ec5fbc"
                    className="c-btn c-btn--red-border"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Take English Assessment
                    <IconArrowRightRed />
                  </a>
                </div> */}
              </div>
              <div className="col-lg-6 col-12">
                <Faq title="Unit 1 - Teaching Skills" description={week1} />
                <Faq title="Unit 2 - Language Awareness and Skills" description={week2} />
                <Faq title="Unit 3 - Learner Profile " description={week3} />
                <Faq title="Unit 4 - Materials Assignment" description={week4} />
                <Faq title="Unit 5 - Unknown Language Journal " description={week5} />
                <Faq title="Assesment Criteria" description={assesmentCriteria} />
                <a
                  target="_blank"
                  href={fileTrinityCertTESOLSyllabus}
                  className="c-btn c-btn--red mt-50"
                >
                  <FormattedMessage id="btn.downloadTrinitySyllabus" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section">
          <div className="container">
            <ForthcomingCourses
              cardData={allPrismicCourses.slice(0, isMore ? allPrismicCourses.length : 4)}
              title={{ id: 'forthcomingCourses.title.upcoming' }}
            />
            {allPrismicCourses.length > 4 ? (
              <button
                onClick={() => setIsMore(!isMore)}
                className="c-btn c-btn--red-border load-more-dates"
              >
                {isMore ? 'Show Less Dates' : 'Load More Dates'}
                <IconArrowRightRed />
              </button>
            ) : null}
          </div>
        </section>

        <section className="fifth-section c-student-support-section">
          <div className="container">
            <h2 className="c-title-34">
              <FormattedMessage id="studentSupport.title" />
            </h2>
            <p className="c-text-18">
              <FormattedMessage id="studentSupport.description" />
            </p>
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconAccomodation} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.accommodation" />
                  </h3>
                  <FormattedMessage id="url.accommodation">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconStudentVisa} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.studentVisa" />
                  </h3>
                  <FormattedMessage id="url.working-as-a-teacher.student-visas">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconCulture} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.culture" />
                  </h3>
                  <FormattedMessage id="url.guide-to-living-and-teaching-english-in-barcelona">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <img src={imgDots} className="dots" alt="" />
                <div className="item">
                  <img src={iconBureaucracy} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.bureaucracy" />
                  </h3>
                  <FormattedMessage id="url.working-as-a-teacher.student-visas">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sixth-section">
          <AplicationProcess isOnlineCourse />
        </section>

        <section className="seventh-section">
          <div className="container">
            <h2 className="c-title-38">
              <FormattedMessage
                id="trinityCerttesol.sec7.title"
                values={{
                  accent: (chunk) => <span className="colored no-link">{chunk}</span>,
                }}
              />
            </h2>

            <FormattedMessage id="url.apply.index">
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--red">
                  <FormattedMessage id="sform.signUpNow" />
                  <IconArrowRight />
                </Link>
              )}
            </FormattedMessage>
          </div>
        </section>

        <section className="nineth-section">
          <ReviewsSection
            hideAllReviews
            description={{ id: 'graduates.teflSpanishCourses.description' }}
            title={{ id: 'graduates.teflSpanishCourses.title' }}
          />
        </section>

        <section className="tenth-section c-faqs-section">
          <div className="container">
            <div className="c-faqs-section__title">
              <h2 className="c-title-34">
                {' '}
                <FormattedMessage id="common.faqs" />
              </h2>
              <FormattedMessage id="url.courses.faq">
                {(locale) => (
                  <Link to={locale[0]} className="c-btn c-btn--white">
                    <FormattedMessage id="btn.viewMoreFaqs" />
                    <IconArrowRightRed />
                  </Link>
                )}
              </FormattedMessage>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Faq
                  title="Does this course qualify me to teach English?"
                  description="The Trinity CertTESOL is one of the few courses which are recognised by governments and schools internationally."
                />
                <Faq
                  title="How much will I get paid as an English teacher?"
                  description="The average rate of pay nowadays for new teachers is 12-15€/hour. Working for an academy full time (20 hours) you can expect to earn 1100€/month after tax. If you add on 2 private classes per week (it is easy to do more) you can increase that to 1300€/month."
                />
                <Faq
                  description="No. English is the only language used in the classroom and we want to show you how you can teach without using a word of the foreign language."
                  title="Do I need to speak Spanish to teach English in Spain?"
                />
                <Faq
                  description="Yes, the Trinity CertTESOL qualification is valid for both online and in-person teaching jobs. To make sure you are completely prepared, we also include a bonus 2-day practical teaching workshop at our school in Barcelona, in order to give you classroom experience with real students. "
                  title="Will i be able to work in a classroom after completing this course"
                />
                <Faq
                  description="The teaching practice takes place online via Zoom. You will have a group of students (4-10) connected to your session and a couple of your classmate trainee teachers observing the lesson. Your tutor will also be online to observe the class and provide help where necessary. You’ll learn how to manage the platform, divide students into groups (‘breakout rooms’) and conduct all sorts of fun, dynamic activities, covering speaking, reading, writing, listening, grammar, vocabulary and pronunciation. "
                  title="What will the online teaching practice be like?"
                />
              </div>
              <div className="col-lg-6">
                <Faq
                  description="Normally two weeks of continued job searching. We run our job finding workshop early-on in the course so you can start looking for jobs before the end of the course."
                  title="How long will it take me to find a job?"
                />
                <Faq
                  description="If you are a European Union citizen then you are free to work and study in any of the EU countries. If you are a non-EU citizen then you can stay up to three months as a tourist and you are free to study and do private classes during this time. If you would like to stay longer and obtain a work permit, the easiest way to do this is with a student visa, which will allow you to work up to 20 hours per week (which is full time for an English teacher). TEFL Iberia can process a student visa for you, for more detailed information please contact coordination@tefl-iberia.com."
                  title="Do I need a visa?"
                />
                <Faq
                  description="Yes, we can open up new groups with different timetables based on demand."
                  title="I can’t attend because I’m in a different timezone. Will there be different timetables?"
                />
                <Faq
                  description="In Barcelona you can expect to pay 550-600€ for a quality double room in a shared flat in the city centre, then about 150€/week on food and entertainment – this money will stretch quite far as Barcelona can be very cheap compared to the UK / US / Canada / Australia. <br/> <br/> Seville is even cheaper: a double room in a shared flat will cost about 350€ and you can expect to spend about 120€/week on food and entertainment."
                  title="What is the average cost of living in Barcelona?"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(
      filter: {
        data: { show_on_these_pages: { elemMatch: { page: { eq: "online-trinity-course" } } } }
      }
      sort: { order: ASC, fields: data___from }
    ) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            price
            is_price_per_month
          }
        }
      }
    }
  }
`;

export default OnlineTrinityCourse;
